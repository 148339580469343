import React from "react";
import { Navbar } from "../componentes/navbar/navbar";
import { Services } from "../componentes/servicios/servicios";
import { Footer } from "../componentes/footer/footer";

const Servicios = () => {
	return (
		<div>
			<Navbar />
			<div className="body-content">
				<Services />
			</div>
			<Footer />
		</div>
	);
};

export default Servicios;
