import { Footer } from "../componentes/footer/footer";
import { Hero } from "../componentes/hero/hero";
import { HeroBanner } from "../componentes/heroBanner/heroBanner";
import { Navbar } from "../componentes/navbar/navbar";
import { WeAre } from "../componentes/nosotros/nosotros";
import { Services } from "../componentes/servicios/servicios";
function Inicio() {
	return (
		<>
			<Navbar />
			<div className="body-content">
				<Hero />
				<HeroBanner />
				<Services />
				<WeAre />
			</div>
			<Footer />
		</>
	);
}

export default Inicio;
