import React from "react";
import { Navbar } from "../componentes/navbar/navbar";
import { WeAre } from "../componentes/nosotros/nosotros";
import { Footer } from "../componentes/footer/footer";

const Nosotros = () => {
	return (
		<div>
			<Navbar />
			<div className="body-content">
				<WeAre />
			</div>
			<Footer />
		</div>
	);
};

export default Nosotros;
