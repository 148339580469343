import React from "react";
import "./footer.css";
import ubicacion from "../../assets/iconos/location-plus.svg";
import contacto from "../../assets/iconos/phone-outgoing.svg";
import correo from "../../assets/iconos/mail-up.svg";
import CRLogoFooter from "../../assets/imagenes/logo-footer.png";
export const Footer = () => {
	const year = new Date().getFullYear();
	return (
		<section id="contacto" className=" bg-dark cr-footer">
			<div className="container py-5">
				<nav className="d-flex flex-column flex-md-row">
					<div className="logo-footer mb-5 mb-lg-0">
						<img src={CRLogoFooter} />
					</div>
					<div className="footer-items d-flex flex-column flex-lg-row">
						<ul>
							<li>
								<h6>Servicios</h6>
							</li>
							<li className="mt-4 cr-servicios">
								<a href="#inicio">Auditorias Fiscales</a>
							</li>
							<li className="mt-2 cr-servicios">
								<a href="#inicio">Acuerdos Conclusivos</a>
							</li>
							<li className="mt-2 cr-servicios">
								<a href="#inicio">Devoluciones</a>
							</li>
							<li className="mt-2 cr-servicios">
								<a href="#inicio">
									Asesoría en créditos fiscales y cancelación de sellos
									digitales
								</a>
							</li>
							<li className="mt-2 cr-servicios">
								<a href="#inicio">
									Asesoría a trámites relacionados al art 69 b. CFF
								</a>
							</li>
							<li className="mt-2 cr-servicios">
								<a href="#inicio">Asesoría en Vigilancia profunda.</a>
							</li>
							<li className="mt-2 cr-servicios">
								<a href="#inicio">Revisión de requisitos a trámites fiscales</a>
							</li>
						</ul>
						<ul>
							<li>
								<h6>Contacto</h6>
							</li>
							<li className="d-flex cr-icon mt-4">
								<img src={ubicacion} className="" />
								<div className="ubicacion">
									Av. Diaz Ordaz #140 Piso 20, Interior 2013 Col Santa María.
									C.P. 64650 Monterrey N.L.
								</div>
							</li>
							<li className="d-flex cr-icon mt-4">
								<img src={correo} />
								<a href="mailto:administracion@cossruiz.com">
									administracion@cossruiz.com
								</a>
							</li>
							<li className="d-flex cr-icon mt-4">
								<img src={contacto} />
								<a href="tel:+8150000080">81 5000 0080</a>
							</li>
						</ul>
					</div>
				</nav>
				<div className="mt-4 border-top">
					<div className="d-flex justify-content-between mt-4 flex-column flex-md-row text-center text-lg-start">
						<div className="mb-3 mb-md-0">
							COSS RUIZ S.C. Todos los derechos reservados.
						</div>
						<div className="bottom-footer mb-3 mb-md-0">
							Consulta nuestros <a href="#">Términos y condiciones.</a>
						</div>
						<div className="bottom-footer mb-3 mb-md-0">
							Consulta nuestro <a href="#">Aviso de privacidad.</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
