import React from "react";
import CRLogo from "../../assets/imagenes/logo-banner.png";

import "./logo.css";
export const Logo = () => {
	return (
		<div className="logo">
			<img src={CRLogo} alt="Logo" />
		</div>
	);
};
