import React from "react";
import { Route, Routes } from "react-router-dom";
import Inicio from "../pages/Inicio";
import Servicios from "../pages/Servicios";
import Nosotros from "../pages/Nosotros";

export const AppRouter = () => {
	return (
		<>
			<Routes>
				<Route path="/" element={<Inicio />} />
				<Route path="/servicios" element={<Servicios />} />
				<Route path="/nosotros" element={<Nosotros />} />
			</Routes>
		</>
	);
};
