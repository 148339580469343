import React from "react";
import { Link } from "react-router-dom";

import "./servicios.css";
import { ServiceCard } from "../serviceCard/serviceCard";
import saberMas from "../../assets/iconos/hand-finger.svg";
export const Services = () => {
	const currentUrl = window.location.href;
	const parts = currentUrl.split("/");
	const lastPart = parts[parts.length - 1];
	return (
		<section className="services-wrapper">
			<div className="container">
				<div className="service-content">
					<h3>
						¡Explora nuestros servicios y descubre cómo podemos ayudarte a
						alcanzar tus objetivos comerciales y legales!
					</h3>
					<p>
						Nos especializamos en brindar consultoría fiscal integral,
						proporcionando asesoramiento experto y soluciones adaptadas a las
						necesidades específicas de cada cliente, con el objetivo de
						optimizar su situación fiscal y garantizar el cumplimiento
						normativo.
					</p>
				</div>
				<div className="service-items">
					<ul>
						<li>
							<ServiceCard text="Auditorias Fiscales" />
						</li>
						<li>
							<ServiceCard text="Acuerdos Conclusivos" />
						</li>
						<li>
							<ServiceCard text="Devoluciones" />
						</li>
						<li>
							<ServiceCard text="Asesoría en créditos fiscales y cancelación de sellos digitales" />
						</li>
						<li>
							<ServiceCard text="Asesoría a trámites relacionados al art 69 b. CFF" />
						</li>
						<li>
							<ServiceCard text="Asesoría en Vigilancia profunda" />
						</li>
						<li>
							<ServiceCard text="Revisión de requisitos a trámites fiscales" />
						</li>
					</ul>
				</div>
				<div className="saber-mas">
					<Link to={`${lastPart === "servicios" ? "/" : "/servicios"}`}>
						Ir a {lastPart === "servicios" ? "Inicio" : "Servicios"}
					</Link>
					<img src={saberMas} />
				</div>
			</div>
		</section>
	);
};
