import React from "react";
import "./heroBanner.css";
import imgintro from "../../assets/imagenes/intro.png";

export const HeroBanner = () => {
	return (
		<section>
			<div className="intro d-flex flex-column flex-md-row">
				<div>
					<img src={imgintro} alt="intro" />
				</div>
				<div>
					<h1>Despacho de abogados especialistas en materia fiscal.</h1>
					<p>
						En Coss Ruiz, nuestra prioridad es la atención de las necesidades de
						nuestros clientes, en base a las normas y leyes fiscales vigentes,
						salvaguardando sus intereses y encontrando soluciones a cada caso en
						particular.
					</p>
					<p>
						Somos un despacho de profesionales con amplia experiencia, siendo
						nuestras características principales: la honestidad, independencia
						de criterio, objetividad a cada caso en particular y sobre todo
						confidencialidad.
					</p>
				</div>
			</div>
			<div className="divider"></div>
		</section>
	);
};
