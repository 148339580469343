import React, { useState } from "react";
import "./navbar.css";
import { Logo } from "../logo/logo";
import menu from "../../assets/iconos/menu-2.svg";
import closeMenu from "../../assets/iconos/x.svg";

import { Outlet, Link } from "react-router-dom";
export const Navbar = () => {
	const [openMenu, setOpenMenu] = useState(false);
	return (
		<>
			<div class="bg-dark cr-navbar">
				<div class="">
					<nav className="px-3">
						<Logo />
						<ul className="d-none d-md-block">
							<li onClick={() => setOpenMenu(false)}>
								<Link to="/">Inicio</Link>
							</li>
							<li onClick={() => setOpenMenu(false)}>
								<Link to="/nosotros">Nosotros</Link>
							</li>
							<li onClick={() => setOpenMenu(false)}>
								<Link to="/servicios">Servicios</Link>
							</li>
							<li onClick={() => setOpenMenu(false)}>
								<a href="#contacto">Contacto</a>
							</li>
						</ul>
						<div
							className="d-block d-md-none ham-menu"
							onClick={() => setOpenMenu(!openMenu)}
						>
							<img src={openMenu ? closeMenu : menu} />
						</div>
					</nav>
					<div>
						<ul
							className={`burger-menu ${
								openMenu ? "open" : ""
							} position-absolute d-flex d-md-none flex-column text-center w-100 bg-dark`}
						>
							<li>
								<Link to="/">Inicio</Link>
							</li>
							<li>
								<Link to="/nosotros">Nosotros</Link>
							</li>
							<li>
								<Link to="/servicios">Servicios</Link>
							</li>
							<li>
								<a href="#contacto">Contacto</a>
							</li>
						</ul>
					</div>
				</div>
			</div>

			<Outlet />
		</>
	);
};
