import React from "react";
import serviceCardImg from "../../assets/imagenes/servicio.jpeg";
import "./serviceCard.css";
export const ServiceCard = (props) => {
	return (
		<div className="service-card-item">
			<img src={serviceCardImg} alt="Servicio" />
			<div className="service-card-overlay"></div>
			<h4 className="service-card-text">{props.text}.</h4>
		</div>
	);
};
