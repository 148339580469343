import React from "react";
import avatar from "../../assets/imagenes/avatar.png";
import "./nosotros.css";
import { Link } from "react-router-dom";
import saberMas from "../../assets/iconos/hand-finger.svg";

export const WeAre = () => {
	const currentUrl = window.location.href;
	const parts = currentUrl.split("/");
	const lastPart = parts[parts.length - 1];

	return (
		<section className="bg-dark-light py-5 mb-5 text-center">
			<div className="container">
				<h3>
					Somos un equipo de expertos en el ámbito fiscal y legal, comprometidos
					con proporcionar soluciones integrales a empresas nacionales e
					internacionales.
				</h3>
				<div className="d-flex we-are flex-column flex-lg-row intro-we-are">
					<div className="avatar-wrapper">
						<img src={avatar} />
						<h4>Carlos Alberto Coss Fuentes</h4>
						<h5 className="mt-2 text-center">Socio Director</h5>
					</div>
					<div className="avatar-wrapper">
						<img src={avatar} />
						<h4>Mayra Araceli Ruiz Galvan</h4>
						<h5 className="mt-2 text-center">Socio Legal</h5>
					</div>
				</div>
			</div>
			<div className="saber-mas">
				<Link to={`${lastPart === "nosotros" ? "/" : "/nosotros"}`}>
					Ir a {lastPart === "nosotros" ? "Inicio" : "Nosotros"}
				</Link>
				<img src={saberMas} />
			</div>
		</section>
	);
};
