import React from "react";
import "./hero.css";
import { Fade } from "react-awesome-reveal";
import logoBanner from "../../assets/imagenes/logo-banner.png";
export const Hero = () => {
	return (
		<section id="inicio">
			<div className="hero-img"></div>
			<div className="hero-overlay">
				<Fade duration={4000} triggerOnce>
					<img src={logoBanner} />
				</Fade>
			</div>
			<div className="divider" />
		</section>
	);
};
